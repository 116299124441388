<template>
  <ion-page><cyan-page-wrapper :title="title" :backRoute="backRoute" nav1="PUNTO CYAN" :ambito="ambito">
    <centro-picker nextRoute="/punto/" :seedCP="seed" :onlyFull="true" />
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store
} from "@/components/CyanPageWrapper.vue";
import CentroPicker from "@/components/CentroPicker.vue";
import { municipios } from '@/modules/cyanRegions'
import unreact from "@/modules/unreact";

export default defineComponent({
  name: "PuntoPickCentro",
  components: {
    CentroPicker,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    seed() {
      return unreact((this as any).$route.params.seedPC+'') || ''
    }, 

    ambito() {
      if (this.seed in municipios) {
        const m = municipios[(this as any).seed];
				return  m.nombre + ', ' + m.nombrePadre;
      }
      return '';
			
    },
    title() {
			if (this.ambito) {
				return 'Elegir centro (' + this.ambito + ')';
			}
			return 'Elegir centro';
		},
		userLevel() {
      return store.state.userData.nivel || '';
    },
		backRoute() {
			return ((this as any).userLevel.length == 6) ? '/home' : '/puntoPickMpio/' + (this.seed as any).substr(0,4);
		},

  },
  methods: {},
});
</script>
